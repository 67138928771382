import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FIAT_SYMBOLS } from "@sellix/shared/configs";
import Table from "../../components/table";
import { useCustomerContext } from "../../provider/CustomerContext";
import { Tooltip } from "@sellix/shared/components";
import Breadcrumbs from "../../components/breadcrumbs";
import Empty from "../../components/empty";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { ICommission } from "../../types/types";
import Payouts from "./payouts";

import "./index.css";

const Dashboard = () => {

  const { customerInfo, commissions } = useCustomerContext();

  const { t } = useTranslation('translation', { keyPrefix: 'customer.commissions' });

  if(!customerInfo) {
    return null
  }

  const columns: ColumnDef<ICommission>[] = [{
    accessorKey: 'products.title',
    header: t('table.columns.product') as string,
    cell: ({ row }: CellContext<ICommission, unknown>) => row.original.products.map((p, key) => <div key={key} className="dashboard-info-product">{p.title} <br/><span>{p.uniqid}</span></div>)
  }, {
    accessorKey: 'quantity',
    header: t('table.columns.quantity') as string,
    cell: ({ row }: CellContext<ICommission, unknown>) => row.original.products.map((p, key) => <div key={key} className="dashboard-info-quantity">{p.quantity}</div>)
  }, {
    accessorKey: 'products.currency',
    header: t('table.columns.price') as string,
    cell: ({ row }: CellContext<ICommission, unknown>) => row.original.products.map((p, key) => <div key={key} className="dashboard-info-price">{FIAT_SYMBOLS[p.currency]}{p.price}</div>)
  }, {
    accessorKey: 'products.affiliate_revenue_percent',
    header: t('table.columns.percentage') as string,
    cell: ({ row }: CellContext<ICommission, unknown>) => row.original.products.map((p, key) => {
      return <div key={key} className="dashboard-info-percentage">
        {
          row.original.rewards ?
            t('table.reward') :
            `${+customerInfo.special_affiliate_revenue_percent ?
              customerInfo.special_affiliate_revenue_percent :
              p.affiliate_revenue_percent ?
                p.affiliate_revenue_percent :
                customerInfo.shop_affiliate_revenue_percent}%`
        }
      </div>
    })
  }, {
    accessorKey: 'revenue_created_at',
    header: t('table.columns.created') as string,
    cell: ({ row }: CellContext<ICommission, unknown>) => moment(row.original.revenue_created_at * 1000).format('DD MMM YYYY (HH:mm)')
  }, {
    accessorKey: 'total_earned',
    header: t('table.columns.commission') as string,
    cell: ({ row }: CellContext<ICommission, unknown>) => <>{FIAT_SYMBOLS[row.original.total_earned_currency]}{row.original.total_earned}</>
  }];

  // @ts-ignore
  return <div className="dashboard-info">
    <div className="screen-header d-none">
      <Breadcrumbs title={t('pageTitle')} breadcrumbs={[]} />
    </div>

    <div className="dashboard-info-cards">
      <div className="dashboard-info-card">
        <div>{t('analytics.titles.currentBalance')}</div>
        <span>{FIAT_SYMBOLS[customerInfo.affiliate_revenue_currency as keyof typeof FIAT_SYMBOLS]}{(+(customerInfo.affiliate_revenue || 0)).toFixed(2)}</span>
      </div>
      <div className="dashboard-info-card">
        <div>{t('analytics.titles.revenuePercentage')}</div>
        <span>{+customerInfo.special_affiliate_revenue_percent || customerInfo.shop_affiliate_revenue_percent}%</span>
      </div>
      <div className="dashboard-info-card">
        <div>{t('analytics.titles.totalCommission')}</div>
        <span>{FIAT_SYMBOLS[customerInfo.total_earned_currency as keyof typeof FIAT_SYMBOLS]}{(+(customerInfo.total_earned || 0)).toFixed(2)}</span>
      </div>
      <div className="dashboard-info-card">
        <div>{t('analytics.titles.totalReferrals')}</div>
        <span>{customerInfo.total_referrals}</span>
      </div>

      <div className="dashboard-info-card w-100 d-none cursor-pointer">
        <div className="w-auto d-flex align-items-center gap-1">
          {t('analytics.titles.referralLink')}
          <Tooltip onlyCopyIcon noArrow tooltipText="Copy referral link" copiedText={`https://${window.location.host}?rcid=${customerInfo.id}`} name="referral-link" >
          </Tooltip>
        </div>
        <span>{customerInfo.id}</span>
      </div>

      <Payouts />
    </div>

    {commissions.length ?
      <div className="dashboard-info-table mt-4">
        <span>{t('table.title')}</span>
        <Table pageSize={20} data={commissions} columns={columns} className="last-right" />
      </div> :
      <div>
        <Empty isCommissions />
      </div>
    }


  </div>
}

export default Dashboard;
