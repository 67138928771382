import React, { useMemo, useState } from "react";
import { Button, Tooltip } from "@sellix/shared/components";
import Pagination from "../../components/pagination";
import { getCDNLink, random } from "../../utils/config.tsx";
import ClearFilters from "../../components/table/clear-filters";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { IRconProductInfo } from "../../types/types";
import { ArrowRightIcon, PureFileIcon } from '../../assets/icons.tsx';
import { Link } from 'react-router-dom';

interface MobilePlaceholderProps {

}

export const MobilePlaceholder: React.FC<MobilePlaceholderProps> = () => {
  return (
    <div>
      {[1, 2, 3].map((key: number) => (
        <div className="table-mobile-card" key={key}>
          <div className="table-mobile-card-body">
            <div className="table-mobile-card-body-list">
              <div>
                <div>
                  <div className="skeleton skeleton2 small" style={{ width: random(50, 120) }}/>
                </div>
                <div>
                  <div className="skeleton skeleton2 medium" style={{ width: random(100, 180) }}/>
                </div>
              </div>
              <div>
                <div>
                  <div className="skeleton skeleton2 small" style={{ width: random(50, 120) }}/>
                </div>
                <div>
                  <div className="skeleton skeleton2 medium" style={{ width: random(100, 180) }}/>
                </div>
              </div>
              <div>
                <div>
                  <div className="skeleton skeleton2 small" style={{ width: random(50, 120) }}/>
                </div>
                <div>
                  <div className="skeleton skeleton2 medium" style={{ width: random(100, 180) }}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

interface ActionsProps {
  item: IRconProductInfo
  executing: boolean
  executeRconCommand: (invoiceId: number, productId: number) => void
}

const Actions = ({ item, executing, executeRconCommand }: ActionsProps) => {
  if (item.commands_pending_count === 0 && item.commands_failed_count === 0) {
    return null;
  }
  if (item.commands.some((cmd) => {
    const startTime = moment(cmd.start_time),
      now = moment();
    return cmd.execution_type === "MANUAL" && (cmd.status === "PENDING" || cmd.status === "FAILED") && startTime <= now
  })) {
    return <Button
      variant="secondary"
      size="small"
      className="w-100 mt-2"
      disabled={executing}
      onClick={() => executeRconCommand(item.invoice_id, item.id)}
    >
      Execute
    </Button>;
  }

  return null;
};

interface Props {
  data: IRconProductInfo[]
  clearFilters?: () => void
  executing: boolean
  executeRconCommand: (invoiceId: number, productId: number) => void
}

const MobileRconProducts = ({ data, clearFilters, executing, executeRconCommand }: Props) => {

  const { t } = useTranslation("translation", { keyPrefix: "customer.rcon" });

  const SIZE = 3;

  const [currentPage, setCurrentPage] = useState<number | "...">(1);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (+currentPage - 1) * SIZE;
    const lastPageIndex = firstPageIndex + SIZE;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data, SIZE]);

  if(!data.length) {
    if(clearFilters) {
      return <ClearFilters clearFilters={clearFilters} />
    }
    return null;
  }

  return (
    <div>
      {currentTableData.map((item, key) => {
        const isFailed = item.commands_failed_count > 0;
        const isPending = !isFailed && item.commands_pending_count > 0;
        const isSuccess = !isFailed && !isPending && item.commands_success_count > 0;

        const to = `/product/${item.uniqid}`
        return <div className="table-mobile-card" key={key}>
          <Link to={to} className="table-mobile-card-header">
            <div className="table-mobile-card-header">
              <div className="table-mobile-card-header-icon">
                {item.cloudflare_image_id
                  ? <img src={getCDNLink({ id: item.cloudflare_image_id })} alt=""/>
                  : <PureFileIcon />
                }
              </div>

              <div className="table-mobile-card-header-main">
                <Link to={to}>{item.title}</Link>
                <div>{item.uniqid}</div>
              </div>
            </div>
            <div style={{ color: "var(--black5)" }}>
              <ArrowRightIcon/>
            </div>
          </Link>

          <div className="table-mobile-card-body">
            <div className="table-mobile-card-body-list">
              <div>
                <div>{t('table.columns.purchased')}</div>
                <div>
                  {item.commands_created_at
                    ? moment(item.commands_created_at, "YYYY-MM-DD HH:mm:ss").format('DD MMM YYYY (HH:mm)')
                    : null}
                </div>
              </div>

              <div>
                <div>{t('table.columns.startTime')}</div>
                <div>
                  {item.rcon_start_time
                    ? moment(item.rcon_start_time, "YYYY-MM-DD HH:mm:ss").format('DD MMM YYYY (HH:mm)')
                    : null}
                </div>
              </div>
              <div>
                <div>{t('table.columns.status')}</div>
                <div>
                  <div className="d-flex gap-2">
                    <div className={`badge ${isSuccess && 'badge-success'} ${isPending && 'badge-pending'} ${isFailed && 'badge-failed'}`}>
                      {isSuccess && t('status.completed')}
                      {isFailed && t('status.error')}
                      {isPending && t('status.pending')}
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div></div>
                <div>
                  <Actions item={item} executing={executing} executeRconCommand={executeRconCommand}/>
                </div>
              </div>

            </div>
          </div>
        </div>
      })}

      <Pagination
        currentPage={+currentPage}
        totalCount={data.length}
        pageSize={SIZE}
        onPageChange={setCurrentPage}
      />

    </div>
  );
};

export default MobileRconProducts;