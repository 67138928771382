import React, { ChangeEvent, ReactNode } from "react";
import { ChevronRightIcon, ChevronLeftIcon, MoreIcon } from "../../assets/icons";
import Select from "../select";
import { Button } from "@sellix/shared/components";
import { useTranslation } from "react-i18next";

import "./index.css";


interface ITablePagination {
    nextPage: any
    previousPage: any
    getCanPreviousPage: any
    getCanNextPage: any
    setPageSize: any
    data: any
    pageIndex: any
    pageSize: any
    setPagination: any
    getPageOptions: any
    state: any
    setPageIndex: any
    getPageCount: any
    getState: any
    initialPageSize: any
}

const TablePagination = (props: ITablePagination): ReactNode => {

  const {
    nextPage,
    previousPage,
    getCanPreviousPage,
    setPageSize,
    initialPageSize,
    pageSize,
    getPageCount,
    getPageOptions,
    getState,
    getCanNextPage,
    setPageIndex,
  } = props;

  const { t } = useTranslation('translation', { keyPrefix: 'customer.table' });


  const updatePage = (index: number): void => {
    setPageIndex(index)
  }

  const PaginationPages = (): ReactNode => {
    if (getPageCount() <= 7) {
      return getPageOptions().map((index: number) => (
        <Button
          key={index}
          variant="secondary"
          onClick={(): void => {
            updatePage(index)
          }}
          className={index === getState().pagination.pageIndex ? 'active' : ''}
        >
          {index + 1}
        </Button>
      ));
    }

    if (getState().pagination.pageIndex < 4) {
      return (
        <>
          {getPageOptions().slice(0, 5).map((index: number) => (
            <Button
              key={index}
              variant="secondary"
              onClick={(): void => {
                updatePage(index)
              }}
              className={index === getState().pagination.pageIndex ? 'active' : ''}
            >
              {index + 1}
            </Button>
          ))}
          <Button variant="secondary" className="more" disabled>
            <MoreIcon className="" style={{}} />
          </Button>
          <Button
            variant="secondary"
            onClick={(): void => {
              updatePage(getPageOptions().length - 1)
            }}
            className={getPageOptions().length - 1 === getState().pagination.pageIndex ? 'active' : ''}
          >
            {getPageOptions().length}
          </Button>
        </>
      );
    }

    if (getState().pagination.pageIndex > getPageOptions().length - 5) {
      return (
        <>
          <Button
            variant="secondary"
            onClick={(): void => {
              updatePage(0)
            }}
            className={getState().pagination.pageIndex === 0 ? 'active' : ''}
          >
                        1
          </Button>
          <Button variant="secondary" className="more" disabled>
            <MoreIcon className="" style={{}} />
          </Button>
          {getPageOptions().slice(getPageOptions().length - 5).map((index: number) => (
            <Button
              key={index}
              variant="secondary"
              onClick={(): void => {
                updatePage(index)
              }}
              className={index === getState().pagination.pageIndex ? 'active' : ''}
            >
              {index + 1}
            </Button>
          ))}
        </>
      );
    }

    return (
      <>
        <Button variant="secondary" onClick={(): void => updatePage(0)}>
                    1
        </Button>
        <Button variant="secondary" className="more" disabled>
          <MoreIcon className="" style={{}} />
        </Button>
        {getPageOptions().map((index: number) => {
          if (getState().pagination.pageIndex > index - 2 && getState().pagination.pageIndex < index + 2) {
            return (
              <Button
                key={index}
                variant="secondary"
                className={index === getState().pagination.pageIndex ? 'active' : ''}
                onClick={(): void => {
                  updatePage(index)
                }}
              >
                {index + 1}
              </Button>
            );
          }

          return null;
        })}
        <Button variant="secondary" className="more" disabled>
          <MoreIcon className="" style={{}} />
        </Button>
        <Button variant="secondary" onClick={(): void => {
          updatePage(getPageCount() - 1)
        }}>
          {getPageCount()}
        </Button>
      </>
    );
  };

  let PAGES_RANGE: number[] = [10, 20, 30, 40, 50];

  if(initialPageSize) {
    if(initialPageSize > PAGES_RANGE[0]) {
      PAGES_RANGE = [20, 40, 60, 80, 100];
    }
    if(initialPageSize < PAGES_RANGE[0]) {
      PAGES_RANGE = [initialPageSize, 10, 15, 20, 25, 30];
    }
  }

  return (
    <div className={`sellix-table-pagination ${pageSize ? (props.data.length > pageSize ? '' : 'd-none') : (props.data.length <= 10 ? 'd-none' : '')}`}>
      <div className="page-size-container">
        <span>
          {t('page')} {getState().pagination.pageIndex + 1} {t('of')} {getPageOptions().length}
        </span>

        <Select
          value={getState().pagination.pageSize}
          options={PAGES_RANGE.map(ps => ({
            label: `${ps} ${t('rows')}`,
            value: ps,
          }))}
          onChange={(e: ChangeEvent<HTMLSelectElement>): void => setPageSize(Number(e.target.value))}
        />
      </div>

      <div>
        <Button
          variant="secondary"
          onClick={(): void => {
            previousPage()
          }}
          disabled={!getCanPreviousPage}
        >
          <ChevronLeftIcon className="" style={{}} />
        </Button>
        <PaginationPages />
        <Button
          variant="secondary"
          onClick={(): void => {
            nextPage()
          }}
          disabled={!getCanNextPage}
        >
          <ChevronRightIcon className="" style={{}} />
        </Button>
      </div>
    </div>
  );
};

export default TablePagination;
