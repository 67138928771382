import React, { useState, useEffect, ReactNode } from "react";
import { getCustomerInfo as getCustomerInfoAction } from "../dashboard/actions";
import CustomerContext from './CustomerContext';
import { useAuthContext } from "./AuthContext";
import { ICommission, ICustomerInfo, IProducts, IProductsNormalized, IResponse } from "../types/types";


const CustomerProvider = ({ children }: { children: ReactNode }) => {

  const { shopInfo, shopId, getShopInfo } = useAuthContext();

  const [mobileMenu, showMobileMenu] = useState(false);
  const [commissions, setCommissions] = useState<ICommission[]>([]);
  const [customerInfo, setCustomerInfo] = useState<ICustomerInfo>();
  const [affiliatedProducts, setAffiliatedProducts] = useState<IProducts[]>([]);

  const getCustomerInfo = (shopId: number) => {
    getCustomerInfoAction(shopId)
      .then((res: IResponse<ICustomerInfo>) => {
        if(res.status === 200) {
          setCommissions(res.data.invoices || []);
          setCustomerInfo({
            ...res.data.customer,
            ...res.data,
            shop_affiliate_revenue_percent: res.data.shop.affiliate_revenue_percent,
            special_affiliate_revenue_percent: +res.data.customer.affiliate_percentage,
          })
        }
      })
      .catch(() => {})
  }

  useEffect(() => {
    if(shopId) {
      getCustomerInfo(shopId);
    }
  }, [shopId]);

  useEffect(() => {

    if(shopId && shopInfo && customerInfo) {

      const items = (shopInfo.items || []).map((item: IProducts) => {

        const minPriceItem = item.item_type === 'GROUP'
          ? (item.products_bound || []).reduce((res: any, obj: any) => {
            return !res || parseFloat(obj.price_display) < parseFloat(res.price_display) ? obj : res;
          }, null)
          : item;

        return {
          ...item,
          isInfo: item.item_type === 'PRODUCT' && item.type === 'INFO_CARD',
          isSubscription: item.item_type === 'PRODUCT' && item.type === 'SUBSCRIPTION',
          isProduct: item.item_type === 'PRODUCT' && !['INFO_CARD', 'SUBSCRIPTION'].includes(item.type),
          isGroup: item.item_type === 'GROUP',
          minPriceItem: minPriceItem && {
            price: minPriceItem.price,
            price_display: minPriceItem.price_display,
            currency: minPriceItem.currency,
          },
        };
      });

      let affiliatedProducts: IProductsNormalized[] = [];

      items.forEach((product) => {
        if (product.isGroup) {
          (product.products_bound || []).forEach((p: IProducts) => {
            affiliatedProducts.push({
              ...p,
              isSubscription: product.type === 'SUBSCRIPTION',
              isProduct: product.type !== 'SUBSCRIPTION',
            });
          });
        } else if (product.isInfo) { /* empty */ } else {
          affiliatedProducts.push(product);
        }
      });

      affiliatedProducts = affiliatedProducts.filter((product: IProducts) => {

        const isFree = +product.price_display === 0 && !+product.pay_what_you_want;
        const isOnHold = product.on_hold;
        const isOut = !+product.stock;
        const isUnlisted = product.unlisted;
        const isShopAffiliateEnabled = customerInfo.shop_affiliate_revenue_percent;
        const productAffiliate = product.affiliate_revenue_percent || 0;
        const isSpecialAffiliateEnabled = customerInfo.special_affiliate_revenue_percent || 0;

        let enableAffiliate: number | string | null = null;

        if (productAffiliate && +productAffiliate > 0) {
          enableAffiliate = productAffiliate;
        } else if (productAffiliate === 0 && isShopAffiliateEnabled) {
          enableAffiliate = isShopAffiliateEnabled;
        } else if (productAffiliate === -1) {
          enableAffiliate = null;
        }

        return !(isFree || isOnHold || isOut || isUnlisted || !enableAffiliate);
      });

      setAffiliatedProducts(affiliatedProducts);
    }

  }, [shopInfo, shopId, customerInfo?.special_affiliate_revenue_percent, customerInfo?.shop_affiliate_revenue_percent, customerInfo]);


  const toggleMenu = () => {
    getShopInfo(true);
    // @ts-ignore
    getCustomerInfo(shopId);
    showMobileMenu(!mobileMenu);
  }

  const navigateDesktop = () => {
    getShopInfo(true);
    // @ts-ignore
    getCustomerInfo(shopId);
  }

  return (
    <CustomerContext.Provider
      value={{
        commissions,
        customerInfo,
        affiliatedProducts,
        mobileMenu,
        toggleMenu,
        navigateDesktop,
        shopId,
        shopInfo
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
}

export default CustomerProvider;
