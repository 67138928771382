import React, { CSSProperties, ReactNode, useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./style.css";
import "./new-modal.css";

Modal.setAppElement('#modal');

interface IModal {
	width?: number
	height?: number
	children: ReactNode
	className?: string
	confirm?: boolean
	visible?: string
	skipClose?: boolean
	open?: boolean
	closeModal: (a: boolean) => void
	wrapClassName?: string
	modalClassName?: string
}


const ModalContainer = ({
  width,
  height,
  children,
  className,
  confirm,
  visible,
  skipClose,
  open,
  closeModal,
  wrapClassName,
  modalClassName,
  ...rest
}: IModal): ReactNode => {

  const [modalIsOpen, setModalIsOpen] = useState(false);


  const closeModalAction = useCallback((initial: boolean) => {
    setModalIsOpen(false);
    closeModal(initial);
  }, [closeModal]);

  useEffect(() => {
    if(open) {
      openModal();
    } else {
      closeModalAction(true);
    }
  }, [open, closeModalAction]);


  const openModal = (): void => {
    setModalIsOpen(true);
  };


  const customStyles: { content: CSSProperties, overlay: CSSProperties } = { content: {}, overlay: {} };
  if(width) {
    customStyles.content.width = width
    customStyles.content.height = height
  }

  const parentElement = (): HTMLElement => document.querySelector('#modal') as HTMLElement;

  return (
    <Modal
      parentSelector={parentElement}
      isOpen={modalIsOpen}
      onRequestClose={(): void => closeModalAction(true)}
      style={customStyles}
      closeTimeoutMS={0}
      overlayClassName="sellix-overlay"
      className={`sellix-modal ${className ? className : ''} ${confirm ? 'confirm' : ''} ${visible ? 'visible' : ''} ${wrapClassName}`}
      shouldCloseOnOverlayClick={!skipClose}
      portalClassName={modalClassName}
      {...rest}
    >
      {!skipClose && <div className="sellix-modal-close" onClick={(): void => closeModalAction(true)} >
        <FontAwesomeIcon icon={faTimes} style={{ color: "var(--black9)", fontSize: "1.25rem" }} />
      </div>}
      {children}
    </Modal>
  );
}

export default ModalContainer;
