import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../provider/AuthContext";
import Breadcrumbs from "../../components/breadcrumbs";

import "./index.css";

const Profile = () => {

  const { customerProfile } = useAuthContext();
  const { t } = useTranslation('translation', { keyPrefix: 'customer.profile' });

  if(!customerProfile) {
    return null;
  }

  return <div className="profile-screen">
    <div className="screen-header pt-0">
      <Breadcrumbs title={t('mainTitle')} breadcrumbs={[]} />
    </div>

    <div className="w-100">

      <div className="info-table with-shadow mt-0">

        <div className="info-table-header">{t('title')}</div>

        <div className="info-table-body">

          {customerProfile.city || customerProfile.country_code || customerProfile.state || customerProfile.street_address || customerProfile.additional_address_info || customerProfile.postal_code ?
            <div>
              <span>{t('address')}</span>
              <span>{customerProfile.city} {customerProfile.country_code} {customerProfile.state} {customerProfile.street_address} {customerProfile.additional_address_info} {customerProfile.postal_code}</span>
            </div>
            : null}

          <div>
            <span>{t('created')}</span>
            <span>{moment(customerProfile.created_at * 1000).format('DD, MMM YYYY HH:mm')}</span>
          </div>
          <div>
            <span>{t('email')}</span>
            <span>{customerProfile.email}</span>
          </div>
          <div>
            <span>ID</span>
            <span>{customerProfile.id}</span>
          </div>
          <div>
            <span>{t('name')}</span>
            <span>{customerProfile.name} {customerProfile.surname}</span>
          </div>

          {customerProfile.phone || customerProfile.phone_country_code ?
            <div>
              <span>{t('phone')}</span>
              <span>{customerProfile.phone} {customerProfile.phone_country_code}</span>
            </div> : null}
        </div>

      </div>

    </div>
  </div>
}

export default Profile;
