import React from "react";
import { useParams } from "react-router-dom";
import { Tooltip } from "@sellix/shared/components";
import { InvoiceIcon } from "../../../../../assets/icons";
import { useTranslation } from "react-i18next";
import config from "../../../../../utils/config";
import Badges from "./badges";
import { IInvoiceInfo } from "../../../../../types/types";
import { GATEWAYS_OPTIONS } from "@sellix/shared/configs";

import './index.css';

const InvoiceHeader = ({ invoice }: { invoice: IInvoiceInfo }) => {

  const { id } = useParams();
  const { gateway = "", blockchain } = invoice;

  const { t } = useTranslation('translation', { keyPrefix: "customer.invoices.details.header" });

  return <div className="invoice-details-info">
    <div>
      <div className="invoice-details-info-image">
        {gateway ?
          <div className="position-relative">
            <img src={GATEWAYS_OPTIONS[gateway].imgUrl} alt={gateway} />
            <div className="invoice-details-info-image-blockchain">
              {blockchain ? <img
                alt=""
                className="embed-invoice-details-blockchain"
                src={GATEWAYS_OPTIONS[
                  blockchain === "BEP20" ? "BINANCE" :
                    blockchain === "TRC20" ? "TRON" :
                      blockchain === "ERC20" ? "ETHEREUM" :
                        "POLYGON"
                ].imgUrl}
              /> : null}
            </div>
          </div>
          :
          <InvoiceIcon />
        }
      </div>
      <div>
        <div className="d-flex align-items-center flex-row gap-1">
          <div style={{ color: "var(--black9)", fontWeight: 500 }}>
            {id}
          </div>
          <div>
            <Tooltip onlyCopyIcon tooltipText="Copy ID" copiedText={id} successText={t('idCopied') as string} name="invoice-details-copy" >
            </Tooltip>
          </div>
        </div>
        <span className="cursor-pointer">{gateway}</span>
      </div>
    </div>

    <Badges invoice={invoice} />
  </div>
}


export default InvoiceHeader;
