import React, { useMemo, useEffect, useState } from "react";
import { PureFileIcon } from "../../assets/icons";
import Table from "../../components/table";
import Search from "../../components/search";
import Select from "../../components/select";
import Empty from "../../components/empty";
import Breadcrumbs from "../../components/breadcrumbs";
import moment from "moment";
import { getLicenses } from "./actions";
import { search, random, getCDNLink } from "../../utils/config";
import useMobile from "../../hooks/useMobile";
import MobileProducts from "./mobile-products";
import MobilePlaceholder from "../subscriptions/mobile-placeholder";
import { useTranslation } from "react-i18next";
import { ILicense } from "../../types/types";
import { CellContext, ColumnDef } from "@tanstack/react-table";

import './index.css';


const FILTERS = [
  'uniqid',
  'product_title',
  'license_key'
];
const Licenses = () => {

  const [licenses, setLicenses] = useState<ILicense[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const { t } = useTranslation('translation', { keyPrefix: "customer.licenses" });

  const isMobile = useMobile();

  useEffect(() => {
    // @ts-ignore
    getLicenses()
      .then(res => {
        setLicenses(res.data.products_licenses ?? []);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, []);

  const columns: ColumnDef<ILicense>[] = useMemo(() => [
    {
      accessorKey: 'license_key',
      header: t('table.columns.info') as string,
      cell: ({ row }: CellContext<ILicense, unknown>) => <>
        <div className="product-table-type">
          <div className="product-table-type-icon" >{row.original.product.image_attachment ? <img src={getCDNLink({ id: row.original.product.image_attachment })} alt=""/> : <PureFileIcon />}</div>
          <div className="product-table-type-title">
            <div>{row.original.product.title}</div>
            <div className="product-table-type-date">{row.original.license_key}</div>
          </div>
        </div>
      </>
    }, {
      accessorKey: 'uniqid',
      header: t('table.columns.id') as string,
    }, {
      accessorKey: 'created_at',
      header: t('table.columns.purchased') as string,
      cell: ({ row }: CellContext<ILicense, unknown>) => moment(row.original.created_at * 1000).format('DD MMM YYYY (HH:mm)')
    }, {
      accessorKey: 'expires_at',
      header: t('table.columns.expired') as string,
      cell: ({ row }: CellContext<ILicense, unknown>) => moment(row.original.expires_at * 1000).format('DD MMM YYYY (HH:mm)')
    }, {
      accessorKey: 'status',
      header: t('table.columns.status') as string,
      cell: ({ row }: CellContext<ILicense, unknown>) => {

        const expiredDate = moment(row.original.expires_at * 1000);
        const now = moment();

        return <div className={`badge badge-${expiredDate.isAfter(now) ? 'completed' : 'cancelled'}`}>{expiredDate.isAfter(now) ? 'Active' : 'Expired'}</div>
      }
    }
  ], [t]);

  const placeholder = [
    {
      accessorKey: 'license_key',
      header: t('table.columns.info'),
      cell: () => <div>
        <div className="product-table-type">
          <div className="product-table-type-icon" ><PureFileIcon /></div>
          <div className="product-table-type-title">
            <div className="skeleton skeleton2 small" style={{ width: random(80, 120) }} />
            <div className="skeleton skeleton2 small" style={{ width: random(50, 150) }} />
          </div>
        </div>
      </div>
    }, {
      accessorKey: 'uniqid',
      header: t('table.columns.id'),
      cell: () => <div className="skeleton skeleton2" style={{ width: random(100) }} />
    }, {
      accessorKey: 'created_at',
      header: t('table.columns.purchased'),
      cell: () => <div className="skeleton skeleton2" style={{ width: random(100) }} />
    }, {
      accessorKey: 'expires_at',
      header: t('table.columns.expired'),
      cell: () => <div className="skeleton skeleton2" style={{ width: random(100) }} />
    }, {
      accessorKey: 'status',
      header: t('table.columns.status'),
      cell: () => <div className={`badge badge-service`}>Loading</div>,
    }
  ];


  const filterByStatus = (licenses: ILicense[]) => {
    if (searchStatus === 'all') {
      return licenses;
    } else if (searchStatus === 'ACTIVE') {
      return licenses.filter(({ expires_at }) => {
        const expiredDate = moment(expires_at * 1000);
        const now = moment();

        return expiredDate.isAfter(now);
      });
    } else if (searchStatus === 'EXPIRED') {
      return licenses.filter(({ expires_at }) => {
        const expiredDate = moment(expires_at * 1000);
        const now = moment();

        return !expiredDate.isAfter(now);
      });
    }
  };

  const STATUS_OPTIONS = [
    { value: 'all', label: t('filters.statuses.all') },
    { value: 'ACTIVE', label: t('filters.statuses.active') },
    { value: 'EXPIRED', label: t('filters.statuses.expired') },
  ];

  if(!loading && !licenses.length) {
    return <Empty isLicenses />
  }

  let licensesData = licenses;
  if (searchStatus) {
    licensesData = filterByStatus(licenses) as ILicense[];
  }
  if (searchKey) {
    licensesData = search(licenses, FILTERS, searchKey) as ILicense[];
  }

  return (
    <div className="license-screen">

      <div className="screen-header align-items-start pt-0">
        <Breadcrumbs title={t('pageTitle')} breadcrumbs={[]} />

        <div className="screen-filters gap-2">
          <Select
            size="large"
            value={searchStatus}
            onChange={e => setSearchStatus(e.target.value)}
            options={STATUS_OPTIONS.map(({ label, value }) => ({ label, value }))}
          />
          <Search onChange={setSearchKey} search={searchKey} />
        </div>
      </div>

      <div className="license-screen-container">
        {isMobile ? null : loading ? <Table data={[{}, {}, {}, {}, {}, {}]} isPlaceholder columns={placeholder} /> : (
          <Table
            clearFilters={() => {
              setSearchStatus("all");
              setSearchKey("")
            }}
            cursorPointer
            data={licensesData}
            columns={columns}
          />
        )}

        {isMobile ? loading ? <MobilePlaceholder data={[{}, {}, {}]} /> : (
          <MobileProducts
            data={licensesData}
            clearFilters={() => {
              setSearchStatus("all");
              setSearchKey("")
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Licenses;
