import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Select from "../../components/select";
import Table from "../../components/table";
import Empty from "../../components/empty";
import Search from "../../components/search";
import moment from "moment";
import { orderStatus, random, search, getCDNLink } from "../../utils/config";
import { getRecurring } from "./actions";
import { ArrowRightIcon, ProductIcon, PureFileIcon } from "../../assets/icons";
import useMobile from "../../hooks/useMobile";
import MobileProducts from "./mobile-products";
import MobilePlaceholder from "./mobile-placeholder";
import Subscription from "./details";
import Breadcrumbs from "../../components/breadcrumbs";
import { useTranslation } from "react-i18next";
import { EStatuses, IResponse, ISubscription, ISubscriptionWrapper } from "../../types/types";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { Tooltip } from "@sellix/shared/components";
import { GATEWAYS_OPTIONS } from "@sellix/shared/configs";

import './index.css';

const FILTERS = ["id", "product_id", "status", "gateway", "custom_fields", "customer_id", "stripe_customer_id", "stripe_account", "stripe_subscription_id", "paypal_account",	"paypal_subscription_id", "discord_customer_token",	"discord_customer_refresh_token", "discord_customer_token_expires_at", "discord_integration_removed", "coupon_id", "current_period_end", "upcoming_email_1_week_sent", "trial_period_ending_email_sent", "renewal_invoice_created", "created_at", "updated_at", "canceled_at", "product_title", "customer_name", "customer_surname", "customer_phone", "customer_phone_country_code", "customer_country_code", "customer_street_address", "customer_additional_address_info", "customer_city", "customer_postal_code", "customer_state"];


const Subscriptions = () => {

  // @ts-ignore
  const [bills, setBills] = useState<ISubscription[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("");
  const [searchStatus, setSearchStatus] = useState("all");
  const invoiceTranslate = useTranslation("translation", { keyPrefix: 'customer.invoices.filters.statuses' });
  const { t } = useTranslation('translation', { keyPrefix: "customer.subscriptions" });

  const isMobile = useMobile();

  useEffect(() => {
    getRecurring()
      .then((res: IResponse<ISubscriptionWrapper>) => {
        setBills(res.data.recurring_bills);
      })
      .catch(() => {
      })
      .finally(() => setLoading(false));
  }, []);

  const columns: ColumnDef<ISubscription>[] = [{
    accessorKey: 'id',
    header: t('table.columns.name') as string,
    cell: ({ row }: CellContext<ISubscription, unknown>) => <div className="product-table-type">
      <div className="product-table-type-icon" >
        {row.original.cloudflare_image_id ? <img src={getCDNLink({ id: row.original.cloudflare_image_id })} alt=""/> : <PureFileIcon />}
      </div>
      <div className="product-table-type-title">
        <div>{row.original.type === "v2" ? row.original.product_plan_title : row.original.product_title}</div>
        <div className="product-table-type-date" style={{ whiteSpace: "nowrap" }}>{row.original.id}</div>
      </div>
    </div>
  }, {
    accessorKey: 'gateway',
    header: t('table.columns.gateway') as string,
    cell: ({ row }: CellContext<ISubscription, unknown>) =>
      // @ts-ignore
      row.original.gateway ? (
        <div className="d-flex align-items-center justify-content-start">
          <img className="mr-2" src={GATEWAYS_OPTIONS[row.original.gateway]?.imgUrl} style={{ borderRadius: 4 }} width={15} height={15} alt="" />
          {GATEWAYS_OPTIONS[row.original.gateway]?.title}
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-start">
          {t('table.row.notSelected')}
        </div>
      ),
  }, {
    accessorKey: 'status',
    header: t('table.columns.status') as string,
    cell: ({ row }: CellContext<ISubscription, unknown>) => {
      return <div className="d-flex gap-2">
        <div className={`badge badge-${(row.original.status || "sellix").toLowerCase()}`}>
          {/* @ts-ignore*/}
          {(orderStatus(invoiceTranslate.t)[row.original.status as keyof typeof EStatuses] || '').toLowerCase()}
        </div>
        {row.original.is_evm_approved ?
          <Tooltip name="approved" place="left" tooltipText={`You have allowed us to automatically use your ${row.original.gateway} funds to pay for invoices on recurring bills.`}>
            <div className="badge badge-confirmation">
                Allowance
            </div>
          </Tooltip>
          : null}
        {row.original.status_details ? <div className={`mt-2 badge badge-cancelled`}>Payment Failed</div> : null}
      </div>
    }
  }, {
    accessorKey: 'current_period_end',
    header: t('table.columns.end') as string,
    cell: ({ row }: CellContext<ISubscription, unknown>) => {
      if (!row.original.current_period_end) {
        return '-';
      }

      return moment(row.original.current_period_end * 1000).format('HH:mm DD MMM, YYYY');
    }
  }, {
    accessorKey: 'title',
    header: '',
    enableSorting: false,
    cell: () => <ArrowRightIcon />
  }
  ];

  const navigate = useNavigate();

  const placeholder = [{
    accessorKey: 'id',
    header: t('table.columns.name'),
    cell: () => <div className="d-flex">
      <div className="invoice-list-icon">
        <ProductIcon/>
      </div>
      <div>
        <span className="d-flex mb-1"><div className="mb-2 skeleton skeleton2 small" style={{ width: random(150, 250) }}/></span>
        <span className="invoice-list-id"><div className="skeleton skeleton7 small"
          style={{ width: random(120) }}/></span>
      </div>
    </div>
  }, {
    accessorKey: 'gateway',
    header: t('table.columns.gateway'),
    cell: () => <img src={undefined} className="skeleton skeleton2" width={20} height={20}
      style={{ borderRadius: '.25rem' }} alt=""/>
  }, {
    accessorKey: 'status',
    header: t('table.columns.status'),
    cell: () => <div className={`badge badge-service`}>Loading</div>,
  }, {
    accessorKey: 'current_period_end',
    header: t('table.columns.end'),
    cell: () => <div className="skeleton skeleton2" style={{ width: random(160) }} />
  }, {
    accessorKey: 'title',
    header: '',
    enableSorting: true,
    cell: () => <ArrowRightIcon />
  }];

  if (!loading && !bills.length) {
    return <Empty isSubscriptions />
  }

  const filterByStatus = (bills: ISubscription[]) => {
    if (searchStatus === 'all') {
      return bills;
    } else {
      return bills.filter(({ status }) => status === searchStatus);
    }
  };

  let billsData = bills;
  if (searchStatus) {
    billsData = filterByStatus(bills);
  }
  if (searchKey) {
    billsData = search(bills, FILTERS, searchKey);
  }

  const STATUS_OPTIONS = [
    { value: 'all', label: t('filters.statuses.all') },
    { value: 'TRIALING', label: t('filters.statuses.trialing') },
    { value: 'ACTIVE', label: t('filters.statuses.active') },
    { value: 'CANCELED', label: t('filters.statuses.canceled') }
  ];

  return (
    <div className="bill-screen">

      <div className="screen-header align-items-start pt-0">
        <Breadcrumbs title={t('pageTitle')} breadcrumbs={[]} />
        <div className="screen-filters gap-2">
          <Select
            size="large"
            value={searchStatus}
            onChange={e => setSearchStatus(e.target.value)}
            options={STATUS_OPTIONS.map(({ label, value }) => ({ label, value }))}
          />
          <Search onChange={setSearchKey} search={searchKey} />
        </div>
      </div>

      <div className="bill-screen-container">
        {isMobile ? null : loading ? <Table isPlaceholder data={[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]} columns={placeholder} /> : (
          <Table
            cursorPointer
            data={billsData}
            columns={columns}
            clearFilters={() => {
              setSearchStatus("all");
              setSearchKey("")
            }}
            onClick={(row, e) => {
              if (e.target.tagName !== 'A') {
                navigate(`/subscriptions/${row.id}`);
              }

            }}
          />
        )}

        {isMobile ? loading ? <MobilePlaceholder data={[{}, {}, {}]} /> : (
          <MobileProducts
            data={billsData}
            clearFilters={() => {
              setSearchStatus("all");
              setSearchKey("")
            }}
          />
        ) : null}
      </div>

      <Routes>
        <Route path=':id' element={<Subscription />} />
      </Routes>
    </div>
  );
};

export default Subscriptions;
