import React from "react";
import Modal from "../../../../components/modal";
import { Button } from "@sellix/shared/components";
import { GATEWAYS_OPTIONS } from "@sellix/shared/configs";
import { useTranslation } from "react-i18next";
import EVMWallets from "./wallets";


const CancelEVM = ({ bill, initializeData, showCancelEVMModal, cancelEVMModal }: any) => {

  const { coin, blockchain } = bill.approved_address;
  const { t } = useTranslation('translation', { keyPrefix: "customer.subscriptions.details.cancelModal" });

  return <Modal open={cancelEVMModal} closeModal={() => showCancelEVMModal(false)} skipClose confirm>
    <div className="modal-container" style={{ padding: "1.5rem" }}>
      <div className="modal-icon">
        {/* @ts-ignore*/}
        <img src={GATEWAYS_OPTIONS[`${coin}${blockchain ? `:${blockchain}` : ''}`].imgUrl} alt=""/>
      </div>
      <div className="modal-header" style={{ textAlign: "center", fontWeight: 500, marginBottom: "1.25rem", marginTop: "-0.75rem" }}>
        {/* @ts-ignore*/}
        Reset approved EVM {GATEWAYS_OPTIONS[`${coin}${blockchain ? `:${blockchain}` : ''}`].title} amount<br/>
      </div>
      <div className="modal-description">
        <div className="text-left" style={{ lineHeight: "1.2rem" }}>
          To remove the EVM USDT permission, please follow these steps: <br/>
          <ul className="mt-2 mb-2">
            <li className="mb-1">1. Ensure, if needed, your browser extension is turned on and you are logged in.</li>
            <li className="mb-1">2. Select the wallet and address with which you added this EVM permission.</li>
            <li className="mb-1">3. Click the <span style={{ fontWeight: 600 }}>Reset approved amount</span> button.</li>
            <li className="mb-1">4. Confirm the action in your wallet.</li>
          </ul>
          This action will reset the allowance to 0 and revoke the permission for the specified wallet and address
          associated with your current subscription. Make sure to confirm the transaction in your wallet to complete the
          process.
        </div>
        <span>
          <EVMWallets coin={coin} blockchain={blockchain} initializeData={initializeData} bill={bill} showCancelEVMModal={showCancelEVMModal} />
        </span>
      </div>
      <div className="modal-footer">
        <Button className="w-100" variant="secondary" size="large" onClick={() => showCancelEVMModal(false)}>{t('closeButton')}</Button>
      </div>
    </div>
  </Modal>
}

export default CancelEVM;