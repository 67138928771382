import React, { ReactNode } from "react";
import config from "../../../../../utils/config";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { GATEWAYS_OPTIONS, FIAT_SYMBOLS } from "@sellix/shared/configs";
import { IInvoiceInfo } from "../../../../../types/types";

import "./index.css";

interface InvoiceInfoProps {
	invoice: IInvoiceInfo
}

const InvoiceInfo = ({ invoice }: InvoiceInfoProps): ReactNode => {

  const { t } = useTranslation('translation', { keyPrefix: "customer.invoices.details.overview" });
  const { ip_info = {}, ip, currency, total_display, coupon_code, location, country, created_at, user_agent, cashapp_note, cashapp_cashtag, paypal_payer_email, paypal_email_delivery, gateway, blockchain } = invoice;
  const isFree = parseFloat(total_display) === 0;

  return <div className="info-table with-shadow">

    <div className="info-table-header">{t('title')}</div>

    <div className="info-table-body">
      <div>
        <span>{t('value')}</span><span>{isFree ? t('free') : `${FIAT_SYMBOLS[currency]}${total_display}`}</span>
      </div>
      <div>
        <span>{t('created')}</span>
        <span>{moment(created_at * 1000).utc().format('DD, MMM YYYY HH:mm UTC')}</span>
      </div>

      {!isFree && gateway && GATEWAYS_OPTIONS[gateway].imgUrl &&
        <div>
          <span>{t('gateway')}</span>
          <span className="d-flex justify-content-end align-items-end">
            <img
              alt={gateway}
              className="ml-2"
              style={{ width: 20, height: 20, borderRadius: 5 }}
              src={GATEWAYS_OPTIONS[gateway].imgUrl}
            />
          </span>
        </div>
      }

      {!isFree && blockchain &&
          <div>
            <span>{t('blockchain')}</span>
            <span className="d-flex justify-content-end align-items-end">
              {blockchain}
              <img
                alt={blockchain}
                className="ml-2"
                style={{ width: 20, height: 20, borderRadius: 5 }}
                src={GATEWAYS_OPTIONS[
                  blockchain === "BEP20" ? "BINANCE" :
                    blockchain === "TRC20" ? "TRON" :
                      blockchain === "ERC20" ? "ETHEREUM" :
                        "POLYGON"
                ].imgUrl}
              />
            </span>
          </div>
      }

      {cashapp_note &&
          <div>
            <span>{t('cashappNote')}</span>
            <span>{cashapp_note}</span>
          </div>
      }

      {cashapp_note &&
          <div>
            <span>{t('cashappCashtag')}</span>
            <span>
              {cashapp_cashtag}
            </span>
          </div>
      }

      {!!paypal_email_delivery &&
          <div className="w-100">
            <span>{t('paypalEmailDelivery')}</span>
            <span>{paypal_payer_email}</span>
          </div>
      }
      {!!paypal_email_delivery &&
          <div className="w-100">
            <span>&nbsp;</span>
            <span className="w-auto">
              <div className="badge paypal-badge mt-2">
                <FontAwesomeIcon icon={faEnvelope} />&nbsp;{t('shippedToPaypalPayerEmail')}
              </div>
            </span>
          </div>
      }

      <div>
        <span>{t('coupon')}</span>
        <span>{coupon_code ? coupon_code : t('noCoupon')}</span>
      </div>

      <div>
        <span>{t('IP')}</span>
        <span style={{ wordBreak: "break-word" }}>{ip}</span>
      </div>
      <div>
        <span>{t('country')}</span>
        <span>
          {(country && country !== "N/A") ? <img src={`${config.CDN_FLAGS_URL}${country.toLowerCase()}.svg`} className="flag-icon mr-2" alt="" /> : "N/A"}
          {location}
        </span>
      </div>
      {ip_info.proxy ? <div><span>{t('proxy')}</span><span className="small-badge proxy-label">{t('proxy')}</span></div> : null}
      {ip_info.vpn ? <div><span>{t('VPN')}</span><span className="small-badge proxy-label">{t('VPN')}</span></div> : null}
      {ip_info.tor ? <div><span>{t('TOR')}</span><span className="small-badge proxy-label">{t('TOR')}</span></div> : null}
      {ip_info.isp ? <div><span>{t('ISP')} </span> <span>{ip_info.isp}</span></div> : null}
      <div>
        <span>{t('device')}</span><span>{user_agent}</span>
      </div>
    </div>

  </div>
}

export default InvoiceInfo;
