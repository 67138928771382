import React, { useRef, useEffect, useState, ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import useCustomerAuthCookies from "../hooks/useCustomerAuthCookie";
import AuthContext from "./AuthContext";
import { getCustomerProfile, getCustomerTheme, getGeneralShopInfo } from "../dashboard/actions";
import i18n from "../utils/i18n";
import { ICustomerProfile, ICustomerTheme, IResponse, IShopInfo } from "../types/types";
import useCustomTheme from "../hooks/useCustomTheme.ts";


const AuthProvider = ({ children }: { children: ReactNode }): ReactNode => {

  const [shopInfo, setShopInfo] = useState<IShopInfo | null>(null);
  const [shopId, setShopId] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [customTheme, setCustomTheme] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [localeReady, setLocaleReady] = useState<boolean>(false);
  const [isThemeReady, setIsThemeReady] = useState(false);
  const [customerProfile, setCustomerProfile] = useState<ICustomerProfile | null>(null);
  const [shopLoading, setShopLoading] = useState<boolean>(false);
  const [isExpiredSubscription, setExpiredSubscription] = useState<boolean>(false);

  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const [cookies] = useCookies(["customerToken", "locale"]);
  const switched = useRef(!!cookies.customerToken);
  const { removeCookies } = useCustomerAuthCookies();

  useEffect(() => {
    setCustomTheme(shopInfo?.shop.ui_style_configuration);
  }, [shopInfo?.shop.ui_style_configuration]);

  const getShopInfo = (isNavigation?: boolean) => {

    setShopLoading(true);

    const SHOP_DOMAIN = "local-test-sellix.com";
    const getHostPattern = (host: string): any => host.replace('.', '\\.').replace('-', '\\-');

    const getEncodedShopDomain = (host: string): any => {
      // @ts-ignore
      const regexp = RegExp(`(.*)\\.${getHostPattern(SHOP_DOMAIN)}`);
      const match = host.match(regexp);

      const domain = SHOP_DOMAIN.includes('local-test-sellix.com') ? 'mysellix.io' : SHOP_DOMAIN;

      let shopDomain;
      if (match) {
        shopDomain = `https://${match[1]}.${domain}`;
      }else {
        shopDomain = `https://${host.split(':').shift()}`;
      }

      return encodeURIComponent(shopDomain);
    }

    const name: string = getEncodedShopDomain(window.location.host);

    if(!shopLoading && (!shopId || isNavigation)) {
      getGeneralShopInfo(name)
        .then((res: IResponse<IShopInfo>) => {
          if (res.status === 200) {
            console.log("Shop Ready")
            setShopId(res.data.shop.id);
            setShopInfo(res.data);
          } else if (res.status === 400 && res.error === "Seller subscription has expired.") {
            setExpiredSubscription(true);
          }
        })
        .catch((res) => {
          if(res?.status === 400 && res.error === "Seller subscription has expired.") {
            setExpiredSubscription(true)
          }
        })
        .finally(() => setShopLoading(false));
    }
  }

  const { isCustom, customerPortalDomain, businessName,    hideCustomerAffiliate,

    hideCustomerCommissions,
    customerMainPage,
    hideCustomerRewards,
    hideCustomerSubscriptions,
    hideCustomerLicenses,
    hideCustomerGaming,
  } = useCustomTheme({
    theme: shopInfo?.shop.ui_style_configuration,
    isDark: shopInfo?.shop.dark_mode,
    getShopInfo
  });

  useEffect(() => {
    if(!shopId) {
      getShopInfo();
    }
  }, [shopId]);

  useEffect(() => {
    if (shopId && localeReady) {
      console.log("Locale Initialized:", localeReady)
      getCustomerTheme(shopId)
        .then((res: IResponse<ICustomerTheme>) => {
          if (res.status === 200) {
            const theme = res.data.theme;

            if (theme && theme.assets["favicon.ico"] && theme.assets["favicon.ico"].file && theme.assets["favicon.ico"].file.cdn_url) {

              const faviconUrl = theme.assets["favicon.ico"].file.cdn_url;

              const faviconTypes = [
                { rel: 'icon', type: 'image/x-icon' },
                { rel: 'icon' },
                { rel: 'shortcut icon', type: 'image/x-icon' },
                { rel: 'apple-touch-icon' },
                { rel: 'shortcut icon' },
              ];

              faviconTypes.forEach(({ rel, type }) => {
                const linkElement = document.createElement('link');
                linkElement.rel = rel;
                if (type) {
                  linkElement.type = type;
                }
                linkElement.href = faviconUrl;

                document.head.appendChild(linkElement);
              });

            }

            const detectLocale = () => {
              const supportedLocales = ['en', 'ru', 'fr', 'pl', 'it', 'pt', 'de', 'nl', 'ja', 'zh-CN', 'ar-SA'];
              const defaultLocale = 'en';

              // @ts-ignore
              const browserLocale = navigator.language || navigator.userLanguage;

              console.log("Browser Locale:", browserLocale);

              return supportedLocales.find(lang => browserLocale.startsWith(lang)) || defaultLocale;
            };

            const settings = theme.settings.properties;
            const detectedLocale = detectLocale();
            const currentLocale = detectedLocale || settings.locale.default || cookies.locale || 'en';

            console.log("Default Locale:", settings.locale.default);
            console.log("Cookies Locale:", cookies.locale);
            console.log("Detected Locale:", detectedLocale);
            console.log("Current Locale:", currentLocale);

            try {
              void i18n.changeLanguage(currentLocale);
            } catch (e) {

            }

            // const isDarkMode = settings.color_schema.dark_mode;
            // setUserColors(isDarkMode, isDarkMode ? settings.dark_colors : settings.light_colors)
          }
        })
        .catch(() => {})
        .finally(() => {
          setIsThemeReady(true);
        })
    }
  }, [shopId, cookies.locale, localeReady]);

  useEffect(() => {
    // eslint-disable-next-line prefer-const
    // @ts-ignore
    let checkInterval = null

    const checkI18nInitialized = () => {
      if (i18n.isInitialized) {
        setLocaleReady(true);
        // @ts-ignore
        clearInterval(checkInterval);
      }
    };

    checkInterval = setInterval(checkI18nInitialized, 500);

    return () => {
      // @ts-ignore
      clearInterval(checkInterval);
    };
  }, [i18n.isInitialized]);

  useEffect(() => {

    if(localeReady) {
      getCustomerProfile()
        .then((res: any) => {
          setLoggedIn(true);
          setCustomerProfile(res.data.customer);
          if (pathname.includes("auth") || pathname === "/") {
            if(customerMainPage) {
              navigate(`/${customerMainPage}`, { replace: true });
            } else {
              navigate("/commissions", { replace: true });
            }
          }
        })
        .catch(() => {
          setLoggedIn(false);
          removeCookies("customerToken");
          const searchParams = new URLSearchParams(search);
          if (searchParams.toString().includes("CUSTOMIZATION")) {
            navigate(`/auth${search}`, { replace: true });
          } else {
            navigate("/auth", { replace: true });
          }
        })
        .finally(() => {
          console.log("Portal Ready")
          setLoading(false);
        })
    }

  }, [pathname, isLoggedIn, switched, cookies.customerToken, removeCookies, navigate, localeReady])

  const handleLogout = (): void => {
    removeCookies("customerToken");
    setLoggedIn(false);
    navigate("/auth", { replace: true });
  };

  return <AuthContext.Provider
    value={{
      isLoggedIn,
      customerProfile,
      handleLogout,
      setLoggedIn,
      loading,
      shopId,
      shopInfo,
      isThemeReady,
      customTheme,
      customerPortalDomain,
      businessName,
      isCustom,
      customerMainPage,
      hideCustomerCommissions,
      hideCustomerAffiliate,
      hideCustomerRewards,
      hideCustomerSubscriptions,
      hideCustomerLicenses,
      hideCustomerGaming,
      getShopInfo,
      isExpiredSubscription
    }}
  >
    {children}
  </AuthContext.Provider>;
};

export default AuthProvider;
