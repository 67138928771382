import { createContext, useContext } from 'react';
import { CustomerContentType } from '../types/types';

export const initialValue: CustomerContentType = {
  commissions: [],
  customerInfo: undefined,
  affiliatedProducts: [],
  toggleMenu: () => {},
  navigateDesktop: () => {},
  mobileMenu: false,
  shopId: null,
  shopInfo: null
}

const CustomerContext = createContext(initialValue);

export const useCustomerContext = () => useContext(CustomerContext) as CustomerContentType;

export default CustomerContext;
