import React, { useCallback, useMemo } from "react";
import { Tooltip, Button } from "@sellix/shared/components";
import Table from "../../components/table";
import Empty from "../../components/empty";
import { getCDNLink } from "../../utils/config";
import { CopyIcon, PureFileIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { ICustomerInfo, IProductsNormalized } from "../../types/types";
import { calculatePrice, getPercent } from "./mobile-products";

import "./index.css";
import { useAuthContext } from "../../provider/AuthContext.ts";

export interface IVariant {
    description: string
    price: number
    title: string
}

interface AffiliateTableProps {
    customerInfo: ICustomerInfo
    data: IProductsNormalized[]
}

const AffiliateTable = ({ data, customerInfo }: AffiliateTableProps) => {

  const { t } = useTranslation('translation', { keyPrefix: 'customer.affiliates' });
  const { customerPortalDomain } = useAuthContext();

  const createPrice = useCallback(({ row: { original } }: CellContext<IProductsNormalized, unknown>) => {
    return calculatePrice(original, customerInfo.shop_affiliate_revenue_percent, customerInfo.special_affiliate_revenue_percent);
  }, [customerInfo]);

  const createCommission = useCallback(({ row: { original } }: CellContext<IProductsNormalized, unknown>) => {
    return getPercent(original, customerInfo.shop_affiliate_revenue_percent, customerInfo.special_affiliate_revenue_percent);
  }, [customerInfo]);

  const createProduct = useCallback(({ row: { original } }: CellContext<IProductsNormalized, unknown>) => {
    return <div className="product-table-type">
      <div className="product-table-type-icon" >{original.cloudflare_image_id ? <img src={getCDNLink({ id: original.cloudflare_image_id })} alt=""/> : <PureFileIcon />}</div>
      <div className="product-table-type-title">
        <div>{original.title}</div>
        <div className="product-table-type-date">{original.uniqid}</div>
      </div>
      {original.pay_what_you_want ?
        <div className="mx-2 badge badge-sellix">
          {t('row.chooseYourPrice')}
        </div>
        : null}
      {original.isSubscription ?
        <div className="mx-2 badge badge-sellix">
          {t('row.subscription')}
        </div>
        : null}
    </div>
  }, [t]);

  const createButton = useCallback(({ getValue }: CellContext<IProductsNormalized, unknown>) => {
    return <Tooltip tooltipText={t("copyProductLink")} name={`table-copied-${getValue()}`} successText={t("copied")} copiedText={`https://${window.location.host}/product/${getValue()}?rcid=${customerInfo.id}`}>
      <Button variant="secondary" className="affiliate-screen-button w-100">
        <CopyIcon grey={false} color="var(--purple6)" /> {t('row.getLink')}
      </Button>
    </Tooltip>
  }, [t, customerInfo]);

  const columns: ColumnDef<IProductsNormalized>[] = useMemo(
    () => {

      const main = [
        {
          accessorKey: "product",
          header: t('table.columns.product') as string,
          cell: createProduct,
        }, {
          accessorKey: "affiliate_revenue_percent",
          header: t('table.columns.commission') as string,
          cell: createCommission,
        }, {
          accessorKey: "price_display",
          header: t('table.columns.youWillReceive') as string,
          cell: createPrice,
        }
      ];

      if(customerPortalDomain) {
        return main
      } else {
        return [...main, {
          accessorKey: "uniqid",
          header: t('table.columns.link') as string,
          cell: createButton,
          enableSorting: false,
          // className: "last-header-block",
        }]
      }
    }, [createButton, createProduct, createCommission, createPrice, t, customerPortalDomain]);

  const isEmpty: boolean = !data.length;

  if(isEmpty) {
    return <Empty isProducts />
  }

  return <Table pageSize={10} data={data} columns={columns} className="last-right" />
}

export default AffiliateTable;
