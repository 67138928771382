import React, { useCallback, useEffect } from "react";
import { useAuthContext } from "../../provider/AuthContext";
import useMobile from "../../hooks/useMobile";
import {
  AffiliateIcon,
  DashboardIcon,
  InvoicesIcon, LicensesIcon,
  MenuIcon,
  RconGamingIcon, RewardsIcon, SignOutIcon,
  SubscriptionIcon,
  EmailIcon, PhoneIcon, LocationIcon, LangIcon, ThemeIcon, PaymentMethodsIcon
} from "../../assets/icons";

import { useCustomerContext } from "../../provider/CustomerContext";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownItem } from "@sellix/shared/components";
import config, { getCDNLink } from "../../utils/config";
import i18n from "../../utils/i18n.tsx";

import "./index.css";


export const Theme = () => {

  const { customTheme, isCustom } = useAuthContext();
  const { t } = useTranslation("translation", { keyPrefix: "customer.menu" })
  const [isDark, setIsDark] = React.useState<boolean>(false);

  const changeTheme = () => {
    const currentMode = localStorage.getItem("theme") || "light";
    const newMode = currentMode === "dark" ? "light" : "dark";
    localStorage.setItem("theme", newMode);

    setIsDark((newMode) === "dark");
    applyTheme(newMode);
  };

  const applyTheme = (theme: string) => {
    if(!isCustom) {
      document.documentElement.classList.remove("dark", "light");
      document.documentElement.classList.add(theme);
    }
  };

  useEffect(() => {
    if (!customTheme) {
      const currentMode = localStorage.getItem("theme") || "light";
      setIsDark(currentMode === "dark");
      applyTheme(currentMode || "light");
    }
  }, [customTheme]);

  if (customTheme) {
    return null;
  }

  return <label htmlFor="lang" className="header-menu-item color-switch mx-0" style={{ width: "unset" }}>
    <div className="d-flex align-items-center gap-2"><ThemeIcon/> <span>{t("switch")}</span></div>
    <label className="color-switch-container">
      <input checked={isDark} id="lang" name="lang" type="checkbox" onChange={changeTheme} className="color-switch-checkbox"/>
      <div className="color-switch-slider"/>
    </label>
  </label>
}


export const Lang = () => {

  const { t } = useTranslation("translation", { keyPrefix: "customer.menu" })

  const LangTrigger = () => {
    return <div className="header-menu-item">
      <div className="d-flex align-items-center gap-2 justify-content-between">
        <LangIcon/>
        {/* @ts-ignore*/}
        <span>{t("language")}: {config.LANGUAGES[i18n.language].title}</span>
      </div>
      {/* @ts-ignore*/}
      <img src={config.LANGUAGES[i18n.language].imgUrl} alt="" style={{ width: "1.25rem" }}/>
    </div>
  }

  const onUpdateLanguage = (lang: string) => {
    try {
      void i18n.changeLanguage(lang);
    } catch (e) {

    }
  }

  return <span className="navigation m-0 p-0" style={{ width: "unset" }} onClick={(e) => {
    e.preventDefault();
    e.stopPropagation();
  }}>
    <Dropdown className="navigation-dropdown lang-dropdown" position="bottom" dropdownTrigger={<LangTrigger/>}>
      {Object.keys(config.LANGUAGES).map((l, key) => (
        <DropdownItem key={key} onClick={() => onUpdateLanguage(l)}
          className={`navigation-item ${i18n.language === l ? 'active' : ''}`}>
          <>
            {/* @ts-ignore*/}
            <img src={config.LANGUAGES[l].imgUrl} alt="" style={{ width: "1.25rem" }}/>
            {/* @ts-ignore*/}
            <span>{config.LANGUAGES[l].title}</span>
          </>
        </DropdownItem>
      ))}
    </Dropdown>
  </span>
}


export const Layout = () => {

  const { customerProfile, handleLogout, businessName,   hideCustomerAffiliate, customerMainPage, hideCustomerRewards, hideCustomerSubscriptions, hideCustomerLicenses, hideCustomerGaming, hideCustomerCommissions } = useAuthContext();
  const { shopInfo, toggleMenu, navigateDesktop } = useCustomerContext();
  const isMobile = useMobile();
  const { t } = useTranslation("translation", { keyPrefix: "customer.menu" })
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const setActive = (name: string) => pathname.includes(name) ? "active" : "";
  const productImage = shopInfo?.shop.cloudflare_image_id ? getCDNLink({
    id: shopInfo.shop.cloudflare_image_id,
    type: 'shopItem'
  }) : null;

  const navigateToPaymentMethods = useCallback(() => {
    navigate("/payment-methods");
  }, []);

  if (!customerProfile) {
    return null
  }

  const ProfileTrigger = () => <div className="header-info unselectable">
    <div>
      {(customerProfile.email || "").charAt(0)}
    </div>
    <div>
      <div>
        {customerProfile.name} {customerProfile.surname}
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 6.5L8 10.5L12 6.5" stroke="#A2AAB4" strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round"/>
        </svg>
      </div>
      <span>{(customerProfile.email || "").substring(0, (customerProfile.email || "").indexOf('@'))}</span>
    </div>
  </div>;

  const InvoiceTrigger = () => <div className={`navigation-item ${setActive('subscriptions')} ${setActive('invoices')} ${setActive('licenses')}`}>
    {
      pathname.includes('/subscriptions') ? <><SubscriptionIcon /> {t('subscriptions')}</> :
        pathname.includes('/licenses') ? <><LicensesIcon /> {t('licenses')}</> :
          <><InvoicesIcon /> {t('invoices')}</>
    }
  </div>

  const hidedMenusCount = [hideCustomerAffiliate, hideCustomerRewards, hideCustomerGaming, hideCustomerCommissions].filter((a) => a).length;

  return <div className="header">
    <div className="header-container flex-wrap">

      <div className="d-flex gap-6">
        <div className="d-flex align-items-center">
          {isMobile ? <div className="position-relative cursor-pointer mr-3" onClick={toggleMenu}>
            <MenuIcon />
          </div> : null}

          <a href="/" className="header-logo">
            {productImage ? <img src={productImage} alt=""/> : businessName || shopInfo?.shop.name}
          </a>
        </div>

        {isMobile ? null : <div className="navigation navigation-header">
          {hideCustomerCommissions ? null : <NavLink onClick={navigateDesktop}  to="/commissions" className={({ isActive }) => isActive ? "active navigation-item" : "navigation-item"}><DashboardIcon/><span>{t('dashboard')}</span></NavLink>}
          {hideCustomerAffiliate ? null : <NavLink onClick={navigateDesktop}  to="/affiliate" className={({ isActive }) => isActive ? "active navigation-item" : "navigation-item"}><AffiliateIcon/><span>{t('affiliates')}</span></NavLink>}

          {hideCustomerSubscriptions && hideCustomerLicenses ?
            <NavLink onClick={navigateDesktop}  to="/invoices" className={({ isActive }) => isActive ? "active navigation-item" : "navigation-item"}><InvoicesIcon/> <span>{t('invoices')}</span></NavLink>
            :
            hidedMenusCount >= 2 ?
              <>
                <NavLink onClick={navigateDesktop}  to="/invoices" className={({ isActive }) => isActive ? "active navigation-item" : "navigation-item"}><InvoicesIcon/> <span>{t('invoices')}</span></NavLink>
                {hideCustomerSubscriptions ? null : <NavLink onClick={navigateDesktop}  to="/subscriptions" className={({ isActive }) => isActive ? "active navigation-item" : "navigation-item"}><SubscriptionIcon/> <span>{t('subscriptions')}</span></NavLink>}
                {hideCustomerLicenses ? null : <NavLink onClick={navigateDesktop}  to="/licenses" className={({ isActive }) => isActive ? "active navigation-item" : "navigation-item"}><LicensesIcon/> <span>{t('licenses')}</span></NavLink>}
              </>
              :
              <Dropdown className="navigation-dropdown" position="bottom" dropdownTrigger={<InvoiceTrigger/>}>
                <DropdownItem onClick={() => navigate("/invoices")} className={`navigation-item ${setActive('invoices')}`}>
                  <InvoicesIcon/> {t('invoices')}
                </DropdownItem>
                {hideCustomerSubscriptions ? null : <DropdownItem onClick={() => navigate("/subscriptions")} className={`navigation-item ${setActive('subscriptions')}`}>
                  <SubscriptionIcon/> {t('subscriptions')}
                </DropdownItem>}
                {hideCustomerLicenses ? null : <DropdownItem onClick={() => navigate("/licenses")} className={`navigation-item ${setActive('licenses')}`}>
                  <LicensesIcon/> {t('licenses')}
                </DropdownItem>}
              </Dropdown>
          }

          {hideCustomerRewards ? null : <NavLink onClick={navigateDesktop}  to="/rewards" className={({ isActive }) => isActive ? "active navigation-item" : "navigation-item"}>
            <RewardsIcon/>
            <span>{t('rewards')}</span>
          </NavLink>}

          {hideCustomerGaming ? null : <NavLink onClick={navigateDesktop}  to="/rcon-gaming" className={({ isActive }) => isActive ? "active navigation-item" : "navigation-item"}>
            <RconGamingIcon/>
            <span>{t('rconGaming')}</span>
          </NavLink>}
        </div>}
      </div>

      <div className="d-flex align-items-center gap-2">
        <Dropdown position="bottom" className="header-profile-dropdown" dropdownTrigger={<ProfileTrigger/>}>

          <div className="header-menu">

            <div className="header-menu-header">
              <span>{customerProfile.name} {customerProfile.surname}</span>
            </div>

            <div className="header-menu-item">
              <EmailIcon/>
              <span>{customerProfile.email}</span>
            </div>

            {customerProfile.phone || customerProfile.phone_country_code ?
              <div className="header-menu-item">
                <PhoneIcon/>
                <span>{customerProfile.phone_country_code} {customerProfile.phone}</span>
              </div> : null}

            {customerProfile.city || customerProfile.country_code || customerProfile.state || customerProfile.street_address || customerProfile.additional_address_info || customerProfile.postal_code ?
              <div className="header-menu-item">
                <LocationIcon/>
                <span>{customerProfile.city} {customerProfile.country_code} {customerProfile.state} {customerProfile.street_address} {customerProfile.additional_address_info} {customerProfile.postal_code}</span>
              </div>
              : null}
            <Theme/>
            <Lang/>
            <div className="header-menu-item justify-content-between" onClick={navigateToPaymentMethods}>
              <div className="d-flex gap-2 align-items-center">
                <span style={{ color: "var(--brand-color)" }}>
                  <PaymentMethodsIcon/>
                </span>
                {t('paymentMethods')}
              </div>

              <span
                className="badge badge-sellix"
                data-tooltip-id="beta"
                style={{
                  color: "var(--purple6)",
                  fontSize: 12,
                  lineHeight: "normal",
                  border: "1px solid var(--purple1)"
                }}
              >
                Beta
              </span>
            </div>
          </div>

          <DropdownItem onClick={() => handleLogout()} className={`navigation-item cursor-pointer mx-2 mb-2`}>
            <SignOutIcon/> {t("logout")}
          </DropdownItem>

        </Dropdown>
      </div>
    </div>
  </div>
}

export default Layout;