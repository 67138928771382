import React from "react";
import { Link } from "react-router-dom";
import { PureFileIcon, DotsIcon } from "../../assets/icons";
import { random } from "../../utils/config";

interface MobilePlaceholderProps {
  data: object[]
  showHeader?: boolean
}

const MobilePlaceholder: React.FC<MobilePlaceholderProps> = ({ data, showHeader = true }) => {

  return (
    <div>
      {data.map((value: object, key: number) => (
        <div className="table-mobile-card" key={key}>

          {showHeader && <div className="table-mobile-card-header">
            <div className="d-flex">
              <div className="table-mobile-card-header-icon">
                <PureFileIcon />
              </div>

              <div className="table-mobile-card-header-main">
                <Link to="/"><div className="skeleton skeleton2 middle" style={{ width: random(120, 250) }} /></Link>
                <div><div className="skeleton skeleton2 small" style={{ width: random(80, 150) }} /></div>
              </div>
            </div>
            <div className="sellix-table-actions">
              <DotsIcon />
            </div>
          </div>}

          <div className="table-mobile-card-body">
            <div className="table-mobile-card-body-list">
              <div><div><div className="skeleton skeleton2 small" style={{ width: random(50, 120) }} /></div><div><div className="skeleton skeleton2 medium" style={{ width: random(100, 180) }} /></div></div>
              <div><div><div className="skeleton skeleton2 small" style={{ width: random(50, 120) }} /></div><div><div className="skeleton skeleton2 medium" style={{ width: random(100, 180) }} /></div></div>
            </div>
          </div>

        </div>
      ))}

    </div>
  );
};

export default MobilePlaceholder;
