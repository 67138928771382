import React from "react";
import ReactCodeInput from "react-code-input";
import { useTranslation } from "react-i18next";

interface AuthFormProps {
    setCode: (e: string) => void
    code: string
    skipFields: boolean
    setFirstName: (e: string) => void
    setLastName: (e: string) => void
    firstname: string
    lastname: string
}

const AuthForm = (props: AuthFormProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'customer.auth.code' });

  return (
    <div className="sellix-product-form mt-3">

      <div className="pb-3">
        <label className="sellix-label mb-2">
          {t('title')}
        </label>
        <span className="sellix-note pb-2 mt-0">
          {t('message')}
        </span>
        <ReactCodeInput
          name="code"
          inputMode="numeric"
          type="number"
          fields={8}
          onChange={(e) => {
            props.setCode(e);
          }}
          value={props.code}
        />
      </div>

      {props.skipFields ? null :
        <div className="sellix-product-form-field">
          <label className="sellix-label">{t('firstName')}</label>
          <input
            type="text"
            onChange={(e) => props.setFirstName(e.target.value)}
            value={props.firstname}
            placeholder={t('firstNamePlaceholder') || ""}
            required={true}
            className="sellix-input"
          />
        </div>
      }

      {props.skipFields ? null :
        <div className="sellix-product-form-field">
          <label className="sellix-label">{t('lastName')}</label>
          <input
            type="text"
            onChange={(e) => props.setLastName(e.target.value)}
            value={props.lastname}
            placeholder={t('lastNamePlaceholder') || ""}
            required={true}
            className="sellix-input"
          />
        </div>
      }
    </div>
  )
}

export default AuthForm;
