import React, { ReactNode } from "react";
import { CommissionsEmptyIcon, AffiliateEmptyIcon, InvoicesEmptyIcon } from "./icons";
import { useTranslation } from "react-i18next";

import "./index.css";

interface IEmpty {
    isCommissions?: boolean
    isProducts?: boolean
    isInvoices?: boolean
    isSubscriptions?: boolean
    isLicenses?: boolean
    isRCON?: boolean
  isPaymentMethods?: boolean
}

const Empty = ({ isCommissions, isProducts, isInvoices, isSubscriptions, isLicenses, isRCON, isPaymentMethods }: IEmpty): ReactNode => {

  const commissionsTranslate = useTranslation('translation', { keyPrefix: 'customer.commissions.empty' });
  const affiliateTranslate = useTranslation('translation', { keyPrefix: 'customer.affiliates.empty' });
  const invoicesTranslate = useTranslation('translation', { keyPrefix: 'customer.invoices.empty' });
  const licensesTranslate = useTranslation('translation', { keyPrefix: 'customer.licenses.empty' });
  const subscriptionsTranslate = useTranslation('translation', { keyPrefix: 'customer.subscriptions.empty' });
  const rconTranslate = useTranslation('translation', { keyPrefix: 'customer.rcon.empty' });
  const paymentMethodsTranslate = useTranslation('translation', { keyPrefix: 'customer.paymentMethods.empty' });

  return <div className="empty">

    <div className="empty-icon">
      {isCommissions ? <CommissionsEmptyIcon /> : null}
      {isProducts ? <AffiliateEmptyIcon /> : null}
      {isRCON || isInvoices || isSubscriptions || isLicenses || isPaymentMethods ? <InvoicesEmptyIcon /> : null}
    </div>

    <div className="empty-title">
      {isCommissions ? commissionsTranslate.t('title') : null}
      {isProducts ? affiliateTranslate.t('title') : null}
      {isInvoices ? invoicesTranslate.t('title') : null}
      {isSubscriptions ? subscriptionsTranslate.t('title') : null}
      {isLicenses ? licensesTranslate.t('title') : null}
      {isRCON ? rconTranslate.t('title') : null}
      {isPaymentMethods ? paymentMethodsTranslate.t('title') : null}
    </div>

    <div className="empty-text">
      {isCommissions ? commissionsTranslate.t('message') : null}
      {isProducts ? affiliateTranslate.t('message') : null}
      {isInvoices ? invoicesTranslate.t('message') : null}
      {isSubscriptions ? subscriptionsTranslate.t('message') : null}
      {isLicenses ? licensesTranslate.t('message') : null}
      {isRCON ? rconTranslate.t('message') : null}
      {isPaymentMethods ? paymentMethodsTranslate.t('message') : null}
    </div>
  </div>
}

export default Empty;
