import React, { useMemo, useState } from "react";
import Pagination from "../../components/pagination";
import { getCDNLink } from "../../utils/config";
import { ArrowRightIcon, PureFileIcon } from "../../assets/icons";
import ClearFilters from "../../components/table/clear-filters";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ILicense } from "../../types/types";


const MobileProducts = ({ data, clearFilters }: { data: ILicense[], clearFilters?: () => void }) => {

  const { t } = useTranslation('translation', { keyPrefix: "customer.licenses" });

  const SIZE = 3;

  const [currentPage, setCurrentPage] = useState<number | '...'>(1);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (+currentPage - 1) * SIZE;
    const lastPageIndex = firstPageIndex + SIZE;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data, SIZE]);

  if(!data.length) {
    if(clearFilters) {
      return <ClearFilters clearFilters={clearFilters} />
    }
    return null;
  }

  return (
    <div>
      {currentTableData.map((item, key) => {

        /**
                 * @param product.uniqid UNIQID
                 */
        const { product, expires_at, updated_at, license_key, uniqid } = item;

        const expiredDate = moment(expires_at * 1000);
        const now = moment();

        return <div className="table-mobile-card" key={key}>

          <div className="table-mobile-card-header">
            <div className="d-flex">
              <div className="table-mobile-card-header-icon">
                {product.image_attachment ? <img src={getCDNLink({ id: product.image_attachment })} alt=""/> : <PureFileIcon />}
              </div>

              <div className="table-mobile-card-header-main">
                <a target="_blank" rel="noreferrer" href={`/product/${product.uniqid}`}>{product.title}</a>
                <div>{license_key}</div>
              </div>
            </div>
            <div style={{ color: "var(--black5)" }}>
              <ArrowRightIcon />
            </div>
          </div>

          <div className="table-mobile-card-body">
            <div className="table-mobile-card-body-list">
              <div>
                <div>{t('table.columns.id')}</div>
                <div>{uniqid}</div>
              </div>
              <div>
                <div>{t('table.columns.purchased')}</div>
                <div>{moment(updated_at * 1000).format('DD MMM YYYY (HH:mm)')}</div>
              </div>
              <div>
                <div>{t('table.columns.status')}</div>
                <div><div className={`badge badge-${expiredDate.isAfter(now) ? 'completed' : 'cancelled'}`}>{expiredDate.isAfter(now) ? 'Active' : 'Expired'}</div></div>
              </div>
              <div>
                <div>{t('table.columns.expired')}</div>
                <div>{moment(expires_at * 1000).format('DD MMM YYYY (HH:mm)')}</div>
              </div>
            </div>
          </div>

        </div>
      })}

      <Pagination
        currentPage={+currentPage}
        totalCount={data.length}
        pageSize={SIZE}
        onPageChange={setCurrentPage}
      />

    </div>
  );
};

export default MobileProducts;
