import React, { ReactNode } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Dashboard from "./dashboard";
import AuthProvider from "./provider/AuthProvider";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Login from "./pages/login";
import config from "./utils/config";
import ToastContainer from "@sellix/shared/components/toast";

import "../node_modules/@sellix/shared/dist/cjs/assets/style.css";


const App = (): ReactNode => {

  const basename = `/customer`;

  return <BrowserRouter basename={basename}>
    <GoogleReCaptchaProvider reCaptchaKey={config.CAPTCHA_SITE_KEY_V3}>
      <>
        <AuthProvider>
          <Routes>
            <Route path='/' index element={<Login />} />
            <Route path='auth' element={<Login />} />
            <Route path='*' element={<Dashboard />} />
          </Routes>
        </AuthProvider>
        <ToastContainer />
      </>
    </GoogleReCaptchaProvider>
  </BrowserRouter>
}

export default App;
